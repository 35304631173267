@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/swiper/dist/css/swiper";
@import "node_modules/font-awesome/scss/font-awesome";

$theme-colors: (
    "dk-teal": #217d89,
    "lt-teal": #83a9a9,
    "dk-gray": #898A8D,
    "lt-gray": #e9e9e9
);

h1, h2, h3, h4, h5, h6, .btn { font-family: 'Comfortaa'; text-transform: uppercase;color: theme-color('dk-teal');text-transform: uppercase}
.font-accent { font-family: 'Comfortaa'; }
body { font-family: 'Open Sans', sans-serif; }

h1 { font-size: 2em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.17em; }
h4 { font-size: 1.12em; }
h5 { font-size: .83em; }
h6 { font-size: .75em; }

input { margin: 5px; }
img { width: 100% }
a:hover {
    text-decoration: none;
}
.sub-nav {
    list-style: none;
    position: absolute;
    z-index: 2;
    visibility: hidden;
    min-width: 100%;
    opacity: 0;
    padding-left: 0px;
    padding: 5px 0px;
    transition: all .2s linear;
    background-color: rgba(255, 255, 255, .90);
    border-radius: 20px;
    border-top-right-radius: 0px;
    right: 0;
    top: 35px;
}
.sub-nav > li {
    white-space: nowrap;
}
.sub-nav > li > a {
    display: block;
    padding: 3px 10px;
    color: #000;
    text-decoration: none;
    font-size: 15px;
    -webkit-transition: color .2s linear;
}
.sub-nav > li:hover > a {
    color: theme-color("dk-teal");
}
#main-nav > li {
    position: relative;
    white-space: nowrap;
} 
#main-nav > li:hover .sub-nav {
    opacity: 1;
    visibility: visible;
}
#main-nav > li > span {
    color: #fff;
    cursor: default;
}
#mobile-nav-icon {
    width: 65px;
    margin-right: -10px;
}
.bg-dark {
    color: #fff;
}
.bg-primary {
    background-color: theme-color("primary") !important;
    color: theme-color("lt-gray");
}

.text-primary {
    color: theme-color("primary") !important;
}

.btn, .btn-sm, .btn-lg {
    border-radius: 0;
    border: none;
}
.btn-primary, .btn {
    background-color: theme-color("dk-teal") !important;
    color: #fff;
    transition: all .2s linear;
    &:hover {
        background-color: #185760 !important;
    }
}
.btn img {
    max-height: 10vh;
}

body section {
    padding: 2.5em 0;
}

header {
    background: url('/img/7696-Homepage-Banner-Background.png'),linear-gradient(135deg, theme-color("dk-teal"), #b7b7b7);
    background-blend-mode: multiply;
    background-size: cover;
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
}
#header-icons {
    padding: 15px;
    background-color: #000;
    border-bottom: solid 3px theme-color("lt-teal");
}
#header-icons div > a > .fa, #header-icons div > a > span {
    color: theme-color("lt-teal"); 
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}
#header-icons div:hover > a > .fa, #header-icons div:hover > a > span {
    color: #fff; 
}
#header-icons .fa {
    font-size: 17px;
    padding-left: 15px;
}
#header-icons span {
    font-size: 15px;
}
#header-icons, #nav-bar {
    background-color: rgba(24, 24, 24, 0.7);
}
#nav-bar {
    width: 100%;
    padding: 25px 0px;
}
#nav-bar-logo {
    width: 100%;
    min-width: 150px;
    max-width: 300px;
    align-self: center;
}
#nav-bar-logo img{
    width: 95%;
}
#nav-bar nav > ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    text-align: right;
}
#nav-bar nav > ul > li{
    display: inline-block;
    font-family: 'Comfortaa';
    text-transform: uppercase;
    
}
#nav-bar nav > ul > li::after{
    content: '';
    display: block;
    padding-bottom: 15px;
    
}
#nav-bar nav > ul > li > a {
    color: #fff;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}
#nav-bar nav > ul > li > a:hover {
    color: theme-color('dk-teal');
}
#nav-bar nav > ul > li:nth-child(1)::before {
    content: '';
    padding-left: 0px;
}
#nav-bar nav > ul > li::before {
    content: '•';
    color: theme-color("dk-teal");
    padding: 0px 10px;
}
#desktop-nav-menu {
    align-self: center;
}
#mobile-nav-btn {
    align-self: center;
}
#mobile-menu {
    position: fixed;
    width: 250px;
    height: 100vh;
    background-color: rgba(24, 24, 24, 1);
    z-index: 3;
    left: -303px;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;  
}
#mobile-menu.open{
    left: 0px;
}
.mobile-menu-txt {
    text-align: right;
    padding-right: 15px;
    opacity: 0;
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 500px;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}
.mobile-menu-txt ul > li {
    list-style: none;
}
.mobile-menu-txt ul > li > a {
    color: #fff;
}
.mobile-menu-txt.open {
    opacity: 1;
    bottom: auto;
    top: 70px;
}
#close-mobile {
    text-align: right;
    font-size: 28px;
    color: #fff;
    padding-right: 30px;
    padding-top: 25px;
}
#close-mobile:hover {
    cursor: pointer;
}
#menu-mobile-menu li {
    position: relative;
    padding: 5px 15px;
    border-right: solid 0px;
    color: #fff;
}
.swiper-slide {
    padding: 25px 0px;
}
.swiper-slide h1, .swiper-slide h3 {
    text-transform: none;
    color: #fff;
    margin-bottom: 25px;
}
.swiper-container {
    height: auto;
}
.swiper-slide .container, .swiper-slide .row {
    height: 100%;
}
.swiper-slide .container .row div {
    align-self: center;
}
.swiper-slide .container .row div:nth-child(2) {
    text-align: center;
}
.swiper-slide .container .row div:nth-child(2) img {
    width: 100%;
    max-width: 500px;
}
.swiper-button-next {
    margin-top: 0;
    top: 120px;
    background: none;
}
.swiper-button-prev {
    margin-top: 0;
    top: 120px;
    background: none;
}
.products-swiper .swiper-button-next, .products-swiper .swiper-button-prev {
    background-image: none;
}
.slider-btn {
    font-family: 'Comfortaa';
    display: block;
    margin: auto;
    text-align: center;
    background-color: #fff;
    color: theme-color('dk-teal') !important;
    padding: 10px 50px;
    border-radius: 50px;
    width: 75%;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    
}
.slider-btn:hover {
    cursor: pointer;
    background-color: theme-color('dk-teal');
    color: #fff !important;
}
footer {
    background-color: #4e4e4e;
}
footer .row div {
    text-align: center;
}
footer .row div h4 {
    color: theme-color('lt-teal');
}
footer address {
    color: #fff;
}
#footer-menu {
    padding: 25px 0px;
}
footer ul {
    list-style: none;
    padding: 0;
}
footer ul > li > a {
    color: #fff;  
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}
footer ul > li > a:hover {
    color: theme-color('lt-teal');  
}
footer ul > li > a::before, footer ul > li > a::after {
    content: '  ';    
}
footer ul > li > a:hover::before, footer ul > li > a:hover::after {
    content: ' • ';    
}
#footer-copyright {
    border-top: solid 3px theme-color('dk-teal');
    padding: 15px 0px;
    font-size: 12px;
    color: #fff;
}
#footer-copyright img {
    width: 100%;
    height: 35px;
}
.banner-txt { text-align: center; }
#home-ebook-cta {
    background-image: url('/img/7696-All-Ceramic-eBook-CTA-Bar-Background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 0px;
    position: relative;
    img {
        width: 90%
    }
    #cta-line {
        position: absolute;
        width: 100%;
        bottom: 70px;
        background-color: theme-color('dk-teal');
        height: 5px;
    }
}
#home-intro {
    text-align: center;
    margin: 50px 0px;
    background-image: url('/img/Emerald-Logomark-RGB.svg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: -35px;
}
.grad-btn {
    display: inline-block;
    color: #fff;
    font-family: 'Comfortaa';
    text-align: center;
    padding: 6px 0px;
    width: 100%;
    max-width: 400px;
    margin: 15px 0px;
    border-radius: 50px;
    background: linear-gradient(90deg, #4e4e4e, theme-color('dk-teal'));
    -webkit-transition: background .2s linear;
    transition: background .2s linear;
}
.grad-btn:hover {
    color: #fff;
    background: linear-gradient(theme-color('lt-teal'), theme-color('dk-teal'));
}
#home-icon-bar {
    background-color: theme-color('lt-gray');
}
#subFoot{
    
}
.home-icon {
    text-align: center;
}
.home-icon img {
    width: 100%;
    max-width: 175px;
    padding-bottom: 10px;
}
#home-scanner-txt {
    align-self: center;
    text-align: center;
}
.intro-txt {
    text-align: center;
}
#subFoot-icon-bar {
    background-color: theme-color('lt-gray');
}
#subFoot-icon-bar-producs {
    background-color: #fff;
}
.resource-btn {
    text-align: center;
}
.resource-btn-right {
    text-align: center;
}

#formContainer {
    background: linear-gradient(90deg, #4d4d4d, #227c87);
    padding: 20px;
    border-radius: 15px;
}
#formContainer input {
    margin: 4px 0px;
    width: 100%;
    border-radius: 10px;
    padding: 5px 5px;
}
.formContainer {
    background: linear-gradient(90deg, #4d4d4d, #227c87);
    padding: 20px;
    border-radius: 15px; 
}
.formContainer input {
    margin: 4px 0px;
    width: 100%;
    border-radius: 10px;
    padding: 5px 5px;  
}
.modal {
  overflow: visible;
  z-index: 3 !important;
  max-width: 1200px;
}

.spiration {
    background: theme-color("gray");
    background: url('/img/spiration.png'), -moz-linear-gradient(135deg, theme-color("gray") 0%, #ffffff 100%);
    background: url('/img/spiration.png'), -webkit-linear-gradient(135deg, theme-color("gray") 0%,#ffffff 100%);
    background: url('/img/spiration.png'), linear-gradient(135deg, theme-color("gray") 0%,#ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='theme-color("gray")', endColorstr='#ffffff',GradientType=1 );
    background-blend-mode: multiply;
}
.spiration.products {
    background: theme-color("lt-gray");
}

.quick-products {
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
}
.quick-products li {
    margin-bottom: 0.5rem;
}
.quick-products li a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.testimonial-swiper {
    overflow: hidden;
    font-size: 0.9rem;
}

.products-swiper {
    list-style: none;
    margin: 0;
    padding: 0 0 35px 0;
    font-size: 1.25rem;
    overflow: hidden;
}
.products-swiper .swiper-slide {
    width: 300px;
    height: 280px;
    border-radius: 50px;
}
.products-swiper .swiper-slide img {
    width: 100%;
    max-width: 250px;
    position: absolute;
    top: -5px;
}
.products-swiper .swiper-slide span {
    position: absolute;
    bottom: 25px;
    text-align: center;
}
.products-swiper li {
    background-color: #fff;
    border: 2px solid theme-color("dk-teal");
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.products-swiper .swiper-slide-active {
    background-color: theme-color("dk-teal");
    color: #fff;
}
.products-swiper li:hover {
    cursor: pointer;
}
.products-swiper .swiper-slide-active:before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18px 18px 0 18px;
    border-color: theme-color("dk-teal") transparent transparent transparent;
}

.products article {
    display: none;
}
.products article.active {
    display: block;
}

.products .panel-group {
    position: relative;
}
.products .panel-group:before {
    content: "";
    position: absolute;
    top: 20px;
    bottom: 17px;
    left: 18px;
    width: 2px;
    background-color: theme-color("dk-teal");
    z-index: 0;
}

.products .panel h3 {
    text-transform: capitalize;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
}
.products .panel h3:hover {
    cursor: pointer;
}
.products .panel h3:before {
    content: "";
    background-color: theme-color("lt-gray");
    border: 1px solid theme-color("gray");
    width: 14px;
    height: 14px;
    margin: 2px 12px 0;
    position: relative;
    z-index: 10;
    border-radius: 50%;
}
.products .panel > div[aria-expanded="true"] h3:before {
    background-color: theme-color("dk-teal");
}
.panel-collapse .panel-body {
    padding-left: 40px;
}
#product-section {
    padding: 0px;
    overflow: hidden;
    position: relative;
}

.zipLabel {
    color: #fff;
}
.placeholder{
    padding-top: 15px;
}

.responseMessage {
    border-radius: 10px;
    background-color: #fff;
    margin: 5px 0px;
    padding: 15px;
    color: green;
    display: none;
    text-align: center;
}
.submit:hover {
    cursor: pointer;
}
#materialsMessage {
    border-radius: 10px;
    background-color: #fff;
    margin: 5px 0px;
    padding: 15px;
    color: green;
    display: none; 
}
form p, form label {
    color: #fff;
}
form p {
    padding: 5px 0px;
    margin-bottom: 0px;
}

.blocker {
    z-index: 4 !important;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-pagination-bullet-active {
    background: #fff;
}
.swiper-slide {
    padding: 25px 0px 0px 0px;
}
/*products landing page */
.product-listing {
    width: 100%;
    height: 260px;
    border-radius: 50px;
    background-color: #fff;
    border: 2px solid #217d89;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    color: #000;
}
.product-listing:hover {
    background-color: #217d89;
    color: #fff;
}
.product-listing span {
    position: absolute;
    bottom: 10px;
    text-align: center;
}
.product-listing img {
    width: 90%;
    max-width: 225px;
    position: absolute;
    top: 5px;
}
.arrow-wrap {
    position: absolute;
    right: 15px;
    z-index: 3;
    top: 120px;
    font-size: 28px;
}
.arrow-animate {
    animation: animate-fa-arrow 2s ease infinite;
}
@keyframes animate-fa-arrow {
    0% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    6% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    12% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    18% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    24% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    30% {
        -webkit-transform: translate(5px,0);
        transform: translate(5px,0);
    }
    100%, 36% {
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
}

/* sm devices */ 
@media screen and (min-width: 544px) { 

} 
#product-section .swiper-wrapper{
  margin-left:calc(-50% + 135px);
}
/* md devices */ 
@media screen and (min-width: 768px) { 
    .resource-btn { text-align: right; }
    .resource-btn-right { text-align: left; }
    .slider-btn{ margin: inherit }
    .banner-txt { text-align: left; }
    
} 
 
/* lg and above */ 
@media screen and (min-width:992px) { 

} 